module.exports = {
	getEnvironment: () => process.env.REACT_APP_ENV || 'dev',
	isProductionEnv: () => process.env.REACT_APP_ENV === 'prod',
	graphQlEndpoint: () => `https://${domain.graphQl[ module.exports.getEnvironment() ]}`,
	apiEndpoint: ( endpoint ) => `https://${domain.api[ module.exports.getEnvironment() ]}${apiEndpoints[ endpoint ]}`,
	cloudfrontEndpoint: () => `https://${domain.cloudfront[ module.exports.getEnvironment() ]}`,
	SECURITY_URL: `${process.env.REACT_APP_HOME_URL}/security/`,
	PRIVACY_POLICY_URL: `${process.env.REACT_APP_HOME_URL}/privacy-policy/`,
	TERMS_OF_SERVICE_URL: `${process.env.REACT_APP_HOME_URL}/terms-of-service/`,
	HOME_UTM_SOURCE_CSP_URL: `${process.env.REACT_APP_HOME_URL}?utm_source=csp`,
	LOGIN_REFRESH: 5 * 60 * 1000, // 5 minutes
	LOGIN_REFRESH_MINIMUM_TIME: 20 * 1000, // 20 seconds
	FINANCE_REDIRECT_TIME: 10 * 1000, // 5 seconds
	PAYMENT_PLAN_NO_EMAIL_WARNING: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Unfortunately, this provider requests that you call them to set up a payment plan.`,
	PAYMENT_PLAN_MESSAGE: ( totalAmount ) =>
		`Great news! If you need to break up your payment, your payment of ${totalAmount?.toLocaleString(
			'en-US',
			{ style: 'currency', currency: 'USD' },
		)} qualifies for a Payment Plan option. Please fill out the terms below & select your payment method.`,

	publicKeyRaw: () => publicKey[ module.exports.getEnvironment() ],
};

// ❗ dev urls do not contain the region (use1) by design ❗
const domain = {
	graphQl: {
		demo: 'csp-graphql.use1.demo.be.payground.com/graphql',
		int: 'csp-graphql.use1.int.be.payground.com/graphql',
		dev: 'csp-graphql.dev.be.payground.com/graphql',
		prod: 'csp-graphql.use1.prod.be.payground.com/graphql',
		stage: 'csp-graphql.use1.stage.be.payground.com/graphql'
	},
	api: {
		demo: 'api.use1.demo.be.payground.com',
		int: 'api.use1.int.be.payground.com',
		dev: 'api.dev.be.payground.com',
		prod: 'api.use1.prod.be.payground.com',
		stage: 'api.use1.stage.be.payground.com'
	},
	cloudfront: {
		demo: 'd25ienhzet5u26.cloudfront.net',
		int: 'd25ienhzet5u26.cloudfront.net',
		dev: 'd25ienhzet5u26.cloudfront.net',
		prod: 'd25ienhzet5u26.cloudfront.net',
		stage: 'd25ienhzet5u26.cloudfront.net',
	},
};

const apiEndpoints = {
	verifyIdentity: '/auth/v1/token/quickPay',
};

const publicKey = {
	demo: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArwZ3yb9Nf9Vlxj6TwuB8\nMb97ZRt7QvU8ZMoKBWnugkSaItaEK89LxHQ1M+13e+BbIBjJ6nVEUEuUNeTLvQ4O\ndzOTA9tMwyeQrhl/M9+MWr8RnbwCpiCrXhN3QAtXTnSgb0hFGb6VdXge9yheZE+N\nf67CJU6DFzefQWiStLC7zrm9+gNEpmWfPXLQUesEKLq3KsJdl400hF5++zZYIC5L\nZHKXEL7AIEV01kels18NBd/uDlvZgMTbqhJnFampLx+YrCBDUIun3fedvb7JjSgv\ng2lP1YUClhtzsJ8287PifTh+qwlvzA5DgUnL5RwDHoUDvztfJpsesaVzQqtLmrfn\nsg/o5VWwgxJB0Rz6zXouXqmCCuKeDu4EVrpPR6bwaA0+RCoczPsv2Bapbqb2CUKP\nvuidwDNbR+gdrkyoBBuTjdamm45kymkNVZR++wG7c1WWpk3nxrdS3xDkwlkUoNz1\nyUyeXOBD9s87WCOkm/HpbaEvfTn0vEab0Cpuktqv1MV1qH0tmGxvHpd0o2ZBsHrc\nICXaiXVNNnsMpxg2VsrtkEq3BOL5U3Owd7yJnuPfiY8Y+bhVsHX5ZF/wxESuv70Z\nNY9+BXsEyCWx8DunZrd8Xwn7hlFDOzbyzTqim6aR8bL5X41OaWiZVdI9DrV/Lmb1\ncPUknwhX6B4zd+MWA2zuWLsCAwEAAQ==\n-----END PUBLIC KEY-----\n',
	int: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArwZ3yb9Nf9Vlxj6TwuB8\nMb97ZRt7QvU8ZMoKBWnugkSaItaEK89LxHQ1M+13e+BbIBjJ6nVEUEuUNeTLvQ4O\ndzOTA9tMwyeQrhl/M9+MWr8RnbwCpiCrXhN3QAtXTnSgb0hFGb6VdXge9yheZE+N\nf67CJU6DFzefQWiStLC7zrm9+gNEpmWfPXLQUesEKLq3KsJdl400hF5++zZYIC5L\nZHKXEL7AIEV01kels18NBd/uDlvZgMTbqhJnFampLx+YrCBDUIun3fedvb7JjSgv\ng2lP1YUClhtzsJ8287PifTh+qwlvzA5DgUnL5RwDHoUDvztfJpsesaVzQqtLmrfn\nsg/o5VWwgxJB0Rz6zXouXqmCCuKeDu4EVrpPR6bwaA0+RCoczPsv2Bapbqb2CUKP\nvuidwDNbR+gdrkyoBBuTjdamm45kymkNVZR++wG7c1WWpk3nxrdS3xDkwlkUoNz1\nyUyeXOBD9s87WCOkm/HpbaEvfTn0vEab0Cpuktqv1MV1qH0tmGxvHpd0o2ZBsHrc\nICXaiXVNNnsMpxg2VsrtkEq3BOL5U3Owd7yJnuPfiY8Y+bhVsHX5ZF/wxESuv70Z\nNY9+BXsEyCWx8DunZrd8Xwn7hlFDOzbyzTqim6aR8bL5X41OaWiZVdI9DrV/Lmb1\ncPUknwhX6B4zd+MWA2zuWLsCAwEAAQ==\n-----END PUBLIC KEY-----\n',
	dev: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0XAMHdooJw/nr84Zu9xe\n8dkypRH8CRDWq6bef9BUF3gWDb0lzZt7plqnLN8Hsr1bPduToXzDiiquz7Poifq1\nRKwaO9gAeY8dNJExijfgFUe5zdXcqsivqEqkWELaLM5b1go1vpiyeOJHzKrFoIsJ\npubgXGOG8K1+fk3lac560n0k+A7YconCCXBn9MheMGykCSq2krvdb9fEe+Kki/rS\n0Rv1EQiIz1tz5BUDamZV3SzlIbx7rSHoHyUD+qJb74Pk99P8hu6xTOtgxAT7+Ns4\n7wNNfBaUOCreYvnyTzZ9857AMDVbBfMECVsOPVtdISPcRWJh/1v6p3+HrSFz1NX7\nb8mulliDmRhwzpAWbj4uEGNVv3ZNgo6erLX5gPDtdgp8UJexDoqP6DdAYIBrRvHW\nVW3nVflf2dNA/y3JCgKH98ptc2bdjgiBamxDJAcPcIK6S5ulKhlOfCIK1XBRFxe5\nbzdBnTOs9/qClOa8iCy116QU90f52elLVirQQpjchicpjKnv9Ocf7+bEDOKHMkdJ\nyffAM87UOx3R58tTIByaOIg+dpDKO9oiyqWMN7Q2AMkw7J6wM0s+b9R6d3AQWKzD\nKIWSFDPOM82XEZkIcIUrBIWGoitpyo/fr05BCYT93IecN/y0htWf4aOz15mTSTIW\necO1cD+uxI35iqB0Apak68MCAwEAAQ==\n-----END PUBLIC KEY-----\n',
	prod: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAruqJVIN7RSyjjI1OIizx\nnySZviBxWfDLnAzTQo3SQ958SUOSZBfllaCiEA+KARbh51fAURuliREp8Po6Ly97\nF4XOePFbVsM+XnyH1F6Vxmab7odwTY7oUpgxgyrRT20vFkziVXNsOcbcr519F2RW\nwT3goqAeqjpStl6L69hz8DGCT5SC3CH546YMQ6oXV/MWKi0e1fumyaJ5bJfHC5/s\nnUvK6s5IsLjDlNci0GpwzvIhd0GeCP85rGrFWS578Myt1ZCbdJOwjPK6+RZ57TJK\nHReFFy0vsTQ7tkUSoNU2qBeuHgZqnnIUBOjIds88B/6HwL3KXcDTqDwE8E1onK60\nqIBe6914U6ySizDsH7E1iBBeyTKUcFhMBafZSXalaEozHUw38h43lJiLCNjDbPZ/\nAfJLQVVyG/oGCaSbUJhGMqfWSdam7zSsWa2k39yEAqQ1NgmpjAPsPiUNY+Cg3q7O\ne37EcrsrJBzXHQ0R7LDtHkFwUihE+tkuTRwl0AzJTcK0KYSYHVbGN0zpTATOP6pb\nHsKFfjSC/7c27vvhzJQMCJnxJh+Cduu2EPAPOBdnEHCdzp3R/XVLDzYbpyiHqSIV\nbHWHcIePrs9XzTxG8drJaSYVjarfcSoiyncZ+R6xoBkutlfXZGic8Ldza+ggCbWP\nLB3NJ3V6a1FbPLNtcI5241kCAwEAAQ==\n-----END PUBLIC KEY-----\n',
	stage: '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA2PG3fBhTdtAOs4DCkTsj\nW3vAVmnqvkvlmQhZN2VwxoFH1bWwvYmQxjYNmvtKLJgO0ETlMe+MFMT+P0V1qRPF\n0391GKHMTHGdGDBnO+t/PK7XuNJXkG97PoRfkzaplSUWE6kFXNErczyfU0QIQjUt\nFrIkdfcvCdNF06mo63kbx9aza7aBNrHf9Z084ZCVLfaeEQ3Y0mNUHjaJmVkTVwsW\n5wD5vJdUwFU8LP6F+e3U2j3oL6U4lzdYyEpqhftB1pPf5DKn7q8zpyO0veuEBjdj\ndwiIoV3ZGKAGBm8AKGrKOsB6Od5hWk4eH614TcXQ2IcgWNiJxEjKihPhuooQS67Q\nOZrWrfNGo3q3z0ec3glo3aqYsEtDeZH9r0JZ0JDR/5l9d5H64FzIeyuJwZ5oj6gP\nfspsnzBNKCz+szhIb/RzrnpXRYoMhOLtzLet9H26w+edUGwsS9kW8fVhMMxthKxp\nDahu5ncvtfiU/F2e/p+18Nq9cYitW2DpEHp6D4vODt/EQXZ8S5RhIEXY1bjHd6um\nvwbXbuR6AGgbQAXxgXRqgIj3Gph8y7DVEvTBS8q83Nek0wltObHFL96m09MNDwoG\n4hanUNpXIon5EAK9vJdqVdwr/9wL1dsi/x5eWfKufhuPtZFI7O09WwC2s/F3Nkkt\nsltDUdltDX5o2jw43+6PJZ0CAwEAAQ==\n-----END PUBLIC KEY-----\n',
};
